import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import React from "react"
import EstimatedReadingTime from "../../components/blog/EstimatedReadingTime"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/common/Container"
import Layout from "../../components/layouts/Default"
import SchemaBlogpost from "../../components/layouts/schemas/SchemaBlogpost"
import { Disclosure } from "@headlessui/react"
import { MinusIcon, PlusIcon } from "@heroicons/react/outline"

export default function Page({ data, location }) {
  const blog = data.blog

  const text = parse(blog.content, {
    replace: domNode => {
      // Fix CMS Link for Media
      if (
        domNode.name === "img" &&
        domNode.attribs.src &&
        domNode.attribs.src.startsWith("https://cms.seo-premium-agentur.de")
      ) {
        domNode.attribs.src = `https://cms.seo-premium-agentur.de${domNode.attribs.src.replace(
          "https://cms.seo-premium-agentur.de",
          ""
        )}`
        const props = attributesToProps(domNode.attribs)
        return (
          <img
            {...props}
            title={domNode.attribs.alt}
            alt={domNode.attribs.alt}
          />
        )
      }

      // Fix CMS Link for Media
      if (
        domNode.name === "a" &&
        typeof domNode.attribs?.href !== "undefined" &&
        domNode.attribs.href.startsWith("/storage/uploads/")
      ) {
        const props = attributesToProps(domNode.attribs)
        props.href = props.href.replace(
          "/storage/uploads/",
          `https://cms.seo-premium-agentur.de/storage/uploads/`
        )
        return <a {...props}>{domToReact(domNode.children)}</a>
      }

      // Change normal Link to Button if A-Tag is the only item in P-Tag
      if (
        domNode.name === "a" &&
        typeof domNode.attribs?.title !== "undefined" &&
        domNode.attribs.title.startsWith("[button]")
      ) {
        const props = attributesToProps(domNode.attribs)
        props.title = props.title.replace("[button]", ``)
        props.className += " button"
        return <a {...props}>{domToReact(domNode.children)}</a>
      }
    },
  })

  const dateObject = new Date(blog._created * 1000)
  var day = ("0" + dateObject.getDate()).slice(-2)
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2)
  var year = dateObject.getFullYear()

  const dateObjectModified = new Date(blog._modified * 1000)
  var dayModified = ("0" + dateObjectModified.getDate()).slice(-2)
  var monthModified = ("0" + (dateObjectModified.getMonth() + 1)).slice(-2)
  var yearModified = dateObjectModified.getFullYear()

  return (
    <Layout
      location={location}
      title={blog.title_seo}
      desc={blog.desc_seo}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${blog.facebook.childImageSharp.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={blog.facebook.childImageSharp.gatsbyImageData.width}
      imageFacebookHeight={blog.facebook.childImageSharp.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${blog.twitter.childImageSharp.gatsbyImageData.images.fallback.src}`}
    >
      <SchemaBlogpost
        headline={blog.title_seo}
        description={blog.desc_seo}
        imageQuadrat={`${data.site.siteMetadata.siteUrl}${blog.schema.quadrat.gatsbyImageData.images.fallback.src}`}
        imageRechteck={`${data.site.siteMetadata.siteUrl}${blog.schema.rechteck.gatsbyImageData.images.fallback.src}`}
        imageQuer={`${data.site.siteMetadata.siteUrl}${blog.schema.quer.gatsbyImageData.images.fallback.src}`}
        datePublished={`${year}-${month}-${day}`}
        dateModified={`${yearModified}-${monthModified}-${dayModified}`}
      />
      <div className="bg-gray-50">
        <Container noPadding className="py-8 md:py-16">
          <Breadcrumb
            pages={[
              { name: "Blog", href: "/blog/", current: false },
              {
                name: blog.title,
                href: `/blog/${blog.title_slug}/`,
                current: true,
              },
            ]}
            className="mb-5"
          />
          <div className="mb-5 text-center">
            <GatsbyImage
              image={getImage(blog.remoteImage)}
              alt={`${blog.title} | Titelbild`}
              title={`${blog.title} | Titelbild`}
              className="rounded-md shadow-lg"
            />
          </div>
          <div className="mb-8 text-center">
            <div className="mb-3 space-y-5 text-xs md:text-center">
              <dl className="space-y-2">
                <div>
                  <dt className="sr-only">Veröffentlicht am</dt>
                  <dd className="font-medium leading-none">
                    <time dateTime={`${year}-${month}-${day}`}>
                      {dateObject.toLocaleDateString("de-DE", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </time>
                  </dd>
                </div>
                <div>
                  <span className="font-medium leading-none">
                    <EstimatedReadingTime text={blog.content} />
                  </span>
                </div>
              </dl>
            </div>
          </div>
          <article className="pb-8 mx-auto prose">
            <div>
              <h1>{blog.title}</h1>
              <p>{blog.excerpt}</p>
            </div>
            {text}

            {blog.faq && blog.faq.length > 0 && (
              <div className="mt-8">
                <h2>FAQ</h2>
                <dl className="mt-10 space-y-6">
                  {blog.faq.map(faq => (
                    <Disclosure
                      as="div"
                      key={faq.value.Frage}
                      className="bg-white p-2 rounded"
                    >
                      {({ open }) => (
                        <>
                          <dt className="">
                            <Disclosure.Button className="flex w-full p-2 items-start justify-between text-left text-gray-900 rounded hover:bg-gray-200">
                              <span className="text-base font-semibold leading-7">
                                {faq.value.Frage}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel
                            as="dd"
                            className=""
                            unmount={false}
                          >
                            <p className="text-base leading-7 p-2 text-gray-600">
                              {faq.value.Antwort}
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            )}
          </article>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    blog(id: { eq: $id }) {
      excerpt
      _created
      _modified
      title
      title_slug
      content
      title_seo
      desc_seo
      remoteImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      facebook: remoteImage {
        childImageSharp {
          gatsbyImageData(height: 630, width: 1200)
        }
      }
      twitter: remoteImage {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      schema: remoteImage {
        quadrat: childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
        rechteck: childImageSharp {
          gatsbyImageData(aspectRatio: 1.33333333)
        }
        quer: childImageSharp {
          gatsbyImageData(aspectRatio: 1.77777778)
        }
      }
      faq {
        value {
          Antwort
          Frage
        }
      }
    }
  }
`
