import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

const SchemaBlogpost = ({
  headline,
  description,
  imageQuadrat,
  imageRechteck,
  imageQuer,
  datePublished,
  dateModified,
}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      logo: file(relativePath: { eq: "seo-agentur-stuttgart-logo.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": data.site.siteMetadata.siteUrl,
    },
    headline,
    description,
    image: [imageQuadrat, imageRechteck, imageQuer],
    author: {
      "@type": "Person",
      name: "Oliver E. Bahm",
    },
    publisher: {
      "@type": "Organization",
      name: "SEO Premium Agentur Stuttgart",
      logo: {
        "@type": "ImageObject",
        url: `${data.site.siteMetadata.siteUrl}${data.logo.childImageSharp.gatsbyImageData.images.fallback.src}`,
      },
    },
    datePublished,
    dateModified,
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SchemaBlogpost
