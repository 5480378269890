import React from "react"
import sanitizeHtml from "sanitize-html"

const EstimatedReadingTime = ({ text }) => {
  let wordcount = sanitizeHtml(text, {
    allowedTags: [], // No tags are allowed, all will be removed
    allowedAttributes: {}, // No attributes are allowed
  }).split(" ").length
  let readingTimeMin = Math.ceil(wordcount / 200)

  return (
    <>
      {wordcount} Wörter - Lesezeit: ca. {readingTimeMin} Minuten
    </>
  )
}

export default EstimatedReadingTime
